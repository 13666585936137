<template>
  <div class="container">
    <div class="title">应用中心</div>
    <div class="body">
      <div class="bodytop">
        <ul>
          <li class="item" v-for="(app, i) in apps" :key="i">
            <router-link   :to="app.path">
              <img :src="app.imgurl" alt="" />
              <span>{{ app.name }} </span>
           </router-link>
          </li>
        </ul>
      </div>
      <!--
      <div class="nobox">
        <img src="~@/assets/image/application-page/no_logo.png" alt="" />
        <p>暂时没有更多哦，小呗正在努力制作</p>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      apps: [
        {
          path: "/application-report-list",
          name: "汇报",
          imgurl: require("@/assets/image/application-page/gzhb.png"),
        },
        {
          path: "/application-approval-list",
          name: "审批",
          imgurl: require("@/assets/image/application-page/sp.png"),
        },
        {
          path: "/application-notice-list",
          name: "公告",
          imgurl:require("@/assets/image/application-page/gg.png"),
        },
        {
          path: "/application-clock-report-day",
          name: "打卡",
          imgurl:require("@/assets/image/application-page/dk.png"),
        },
      ],
    };
  },
};
</script>

<style lang='scss' scoped>
.container {
  height: 100%;
  background-color: white;
}

.title {
  display: flex;
  align-items: center;
  color: white;
  padding: 3px 26px;
  height: 30px;
  background-color: #4c71db;
}

.body {
  .bodytop {
    padding: 23px 26px 0;
    border-bottom: 1px solid #e9e9e9;
  }
}

.body ul {
  margin-left: 20px;
  display: flex;
}

.body ul li {
  margin-right: 50px;
  margin-bottom: 20px;
}

.body li a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.body li img {
  width: 40px;
  height: 50px;
}

.body li span {
  margin-top: 10px;
  // text-align: center;
}

.nobox {
  min-height: 823px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 216px;
    height: 160px;
    margin-bottom: 25px;
  }
}
</style>
